const Prando = require('prando');
const md5 = require('js-md5');
const Ingredients = require('./ingredients.json').impossibleIngredients;

function getRandomIngredient(seed) {
  const rng = new Prando(seed);
  const encoded = Ingredients[rng.nextInt(0, Ingredients.length - 1)];
  return decodeURI(atob(encoded));
}

$('#ingredient-form').on('submit', (ev) => {
  const $nameInput = $('#ingredient-form #name');
  const $submitButton = $('#ingredient-form button[type=submit]');
  const $spinner = $('#ingredient-form .spinner-border');

  $nameInput.removeClass('border-danger');
  const name = $nameInput.val();
  $submitButton.attr('disabled', 'disabled');
  $spinner.removeClass('d-none');
  $spinner.show();
  setTimeout(() => {
    $submitButton.removeAttr('disabled');
    $spinner.hide();
    $('#ingredient-modal #response').html(getRandomIngredient(md5(name)));
    $('#ingredient-modal').modal();
  }, Math.random() * 2000 + 1000);
  ev.preventDefault();
  ev.stopPropagation();
  return false;
});
